import { defineComponent, ref, onMounted, reactive, toRefs } from "vue";
import { useRoute } from "vue-router";
import { getRecruitData } from "@/http/about";
import { ElMessage } from "element-plus";
export default defineComponent({
  props: {
    recruitList: {
      default: []
    }
  },

  setup() {
    const route = useRoute();
    const activeName = ref("");

    const _this = reactive({
      num: 0,
      // 招聘信息
      recruitList: [],
      pageNo: 1,
      pageSize: 5,
      total: 0
    });

    const methods = {
      /**
       * 获取招聘信息列表
       */
      getEnterpriseDynamicMethod() {
        getRecruitData({
          pageNo: 1,
          pageSize: -1,
          orgCode: sessionStorage.getItem("orgCode") || ""
        }).then(res => {
          if (res.data.code == 200) {
            _this.recruitList = res.data.data.data;

            if (route.query.id) {
              activeName.value = route.query.id;
            }
          } else {
            ElMessage.error(res.statusText);
          }
        });
      }

    };
    onMounted(() => {
      methods.getEnterpriseDynamicMethod();
    });
    return {
      activeName,
      ...toRefs(_this),
      ...methods
    };
  }

});