import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getGeneralInfo } from "@/http/about";
import { INTERFACE_TYPE_ENUM } from "@/utils/constant";
import { ElMessage } from "element-plus";
import RecruitInfo from "./components/recruitInfo.vue";
export default defineComponent({
  components: {
    RecruitInfo
  },

  setup() {
    const router = useRouter();
    const route = useRoute();

    const _this = reactive({
      companyData: {}
    });

    const methods = {
      async getData() {
        const res = await getGeneralInfo({
          orgCode: sessionStorage.getItem("orgCode") || "",
          typeCode: 2
        });

        if (res.data.code == INTERFACE_TYPE_ENUM.SUCCESS) {
          _this.companyData = res.data.data;
        } else {
          ElMessage.error(res.data.message);
        }
      },

      goOnePath() {
        if (route.query.typeCode == "link") {
          window.open(route.query.link, "_blank");
        } else if (route.query.typeCode == "defined") {
          router.push(route.query.url);
        } else {
          router.push(route.query.url + `?onlyName=${route.query.oldName}`);
        }
      }

    };
    onMounted(() => {
      methods.getData();
    });
    return {
      router,
      ...toRefs(_this),
      ...methods,
      route
    };
  }

});